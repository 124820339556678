import React from "react";

export default function Navlink(props) {
  return (
    <a
      href={`#${props.anchor}`}
      onClick={(e) => {
        e.preventDefault();
        const element = document.getElementById(props.anchor);
        element?.scrollIntoView({
          behavior: "smooth",
        });
        if (props.toggleNav) {
          props.toggleNav();
        }
      }}
    >
      {props.text}
    </a>
  );
}
