import React from "react";
import BackgroundText from "./BackgroundText";
import Project from "./Project";
import "../assets/css/projects.css";

class Work extends React.Component {
    constructor() {
        super();
        this.state = {
          projectList: []
        }
    }
    
    componentDidMount() {
        fetch('./projects.json')
        .then(data => data.json())
        .then(data => {
            this.setState({
                projectList: data
            })
        })
    }
    render() {
        return(
            <section id="work" className="flex py-20 flex-col items-center">
                <div className="items-center text-center spacing-y-128">

                    <BackgroundText content="Featured Work" addClass="md:hidden top-0" maxWidth="10em"></BackgroundText>

                    <div className="featured-projects flex flex-col gap-16 md:gap-0">
                        {this.state.projectList.slice(0, 5).map(
                            project =>
                                <Project
                                key  ={project.key}
                                slug ={project.key}
                                url  ={project.url}
                                img1 ={project.img1}
                                img2 ={project.img2}
                                name ={project.name}
                                title={project.title}
                                role ={project.role}
                                year ={project.year}
                                brief ={project.brief}
                                siteLink={project.siteLink}
                                isFeatured = {true}
                                ></Project>
                        )}
                        
                    </div>                    
                </div>
            </section>
        )
    }
}

export default Work;