import React from "react";
import BackgroundText from "./BackgroundText";
import Eduard from '../assets/img/eduard.jpg'

export default function About() {
	return (
		<section id="about" className="rsb-skin-light bg-[--rsb-bg] mx-auto px-5 md:px-16 lg:px-20 py-20 flex flex-col items-center justify-center">
			<BackgroundText content="My Story"></BackgroundText>
			<div className="container mx-auto flex py-20 flex-col items-center">
				<h2 className="pitch text-[24px] md:text-4xl mb-11 font-serif text-white max-w-[700px] block mx-auto text-center">
					One-man army that plans, sketches and develops websites, builds brands and helps startups building their online presence.
				</h2>
				<div className="flex md:gap-[12vw] flex-col md:flex-row">
					<div className="md:w-1/2 flex flex-col md:items-start">
						<h3 className="mb-9">Seasoned WordPress developer creating custom themes and plugins since 2006</h3>
						<p className="subtitle text-xl">Being a WP veteran, I have plenty of experience developing and customizing websites, creating custom themes and plugins that suit the clients’ needs.</p>
					</div>

					<div className="md:w-1/2 flex flex-col md:items-start">
						<h3 className="mb-9">Interactive designer experienced in logo and identity as well as UI/UX design.</h3>
						<p className="subtitle text-xl">I’m passionate about design, typography, user experience, the web and new media technologies, and all things related to visual arts.</p>
					</div>
				</div>

				<div className="flex gap-[12vw] flex-col md:flex-row">
					<div className="md:w-1/2 flex flex-col md:items-start">
						<h4>A Bit of Background</h4>
						<p>Born and raised in Belgrade, Serbia, where I still live today.</p>
						<p>
						For a few years, I lived in Spain, where I developed a deep appreciation for art, architecture, and travel.</p>
						<p>Once back in Belgrade, I delved into the world of digital arts, experimenting with digital painting, 3D modeling, and animation programs.</p>
						<p>I created my first website in high school and have built hundreds since then. In 2006, during college, I founded Ocili—a dynamic community that laid the foundations for many of my subsequent personal projects.
						Since then, I’ve worked on projects ranging from <strong>small startup websites</strong> and <strong>corporate presentations</strong> to complex <strong>international communities</strong>.</p>
					
					
						<h4 className="mt-9">Blogging</h4>
						<p>For the past few years, blogging has been an important part of my life. On <a href="https://resabi.com/kitchen" target="_blank" rel="noreferrer">reSabi Kitchen</a> I share inspiring recipes that blend tradition and creativity. There’s also <a href="https://belgrademyway.com" target="_blank" rel="noreferrer">Belgrade my way</a>, an alternative tour guide of my hometown, and <a href="https://zanimljivasrbija.resabi.com" target="_blank" rel="noreferrer">Zanimljiva Srbija</a>, a blog about the natural beauties and cultural heritage of Serbia.</p>
					</div>

					<div className="relative skills md:w-1/2 flex flex-col md:items-start">
						<h3>My Skills</h3>
						<ul>
							<li>HTML5 / CSS3 / JavaScript</li>								
							<li>WordPress</li>
							<li>Logo & Identity design</li>
							<li>Web and UI design</li>
							<li>Webflow</li>
							<li>React / Vue</li>
							<li>PHP / MySQL</li>
							<li>Illustration</li>
							<li>2D animation</li>
						</ul>

						<img
							className="avatar object-cover object-center rounded rotate-6 hover:rotate-0 transition-all duration-500 transform-gpu"
							alt="Eduard"
							src={Eduard}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}