import React from "react";
import { ReactLenis } from "lenis/dist/lenis-react";

import BackgroundText from "./BackgroundText";
import Project from "./Project";
import "../assets/css/projects.css";

class OtherWork extends React.Component {
    constructor() {
        super();
        this.state = {
          projectList: []
        }
    }
    
    componentDidMount() {
        fetch('./projects.json')
        .then(data => data.json())
        .then(data => {
            this.setState({
                projectList: data
            })
        })
    }
    render() {
        return(

            <section id="past-work" className=" mx-auto flex py-20 flex-col items-center">
                <div className="items-center text-center spacing-y-128">

                    <BackgroundText content="Archive" addClass="md:sticky top-0" maxWidth="10em"></BackgroundText>

                    <div className="featured-projects flex flex-col gap-16">
                        {this.state.projectList.slice(6, this.state.projectList.length).map(
                            project =>
                                <ReactLenis
                                root
                                options={{
                                lerp: 0.1,
                                }}
                                >
                                    <Project
                                    key  ={project.key}
                                    slug ={project.key}
                                    url  ={project.url}
                                    img1 ={project.img1}
                                    img2 ={project.img2}
                                    name ={project.name}
                                    title={project.title}
                                    role ={project.role}
                                    year ={project.year}
                                    brief ={project.brief}
                                    siteLink={project.siteLink}
                                    ></Project>
                                </ReactLenis>
                        )}
                        
                    </div>                    
                </div>
            </section>
        )
    }
}

export default OtherWork;