import React from "react";
// import {Link} from "react-router-dom";
import { useInView } from "react-intersection-observer";

export default function Project(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  });

  let renderImg1 = (props) => {
    if (typeof props.img1 !== "undefined" && props.img1 != null) {
      return (
        <img
          className="project-image-1"
          src={require(`../assets/img/projects/${props.img1}`)}
          alt={`Featured screenshot for ${props.title}`}
        ></img>
      );
    }
  };

  let renderImg2 = (props) => {
    if (typeof props.img2 !== "undefined" && props.img2 != null) {
      if (props.slug === "bgtm") {
        return (
          <video
            className="project-image-2"
            controls
            autoPlay
            loop
            poster={require(`../assets/img/projects/${props.img2}`)}
          >
            <source
              src={require(`../assets/img/projects/list-bgvp-video.mp4`)}
              type="video/mp4"
            ></source>
            Your browser does not support the video tag.
          </video>
        );
      } else {
        return (
          <img
            className="project-image-2"
            src={require(`../assets/img/projects/${props.img2}`)}
            alt={`Additional screenshot for ${props.title}`}
          ></img>
        );
      }
    }
  };

  let renderBGImg = (props) => {
    if (typeof props.img1 !== "undefined" && props.img1 != null) {
      return (
        <div
          className="project-bg-image"
          style={{
            backgroundImage:
              "url(" + require(`../assets/img/projects/${props.img1}`) + ")",
          }}
        ></div>
      );
    }
  };

  return (
    <div
      ref={ref}
      key={props.slug}
      className={`project ${props.slug} ${
        inView ? "in-view" : "not-yet-loaded"
      } fadeup px-5 md:px-20 pb-14 md:pb-16 border-b-[1px] border-b-rsb-border`}
    >
      <div className="container mx-auto flex flex-col lg:flex-row lg:justify-between gap-10 ">
        <div className="project-images flex flex-col sm:flex-row gap-10 items-start">
          <div>{renderImg1(props)}</div>
          <div>{renderImg2(props)}</div>
        </div>
        <div className="project-info text-left basis-1/4 flex flex-col gap-8">
          <h3 className="project-title">{props.title}</h3>
          <div className="project-year project-meta">
            <span className="project-meta-font pr-4">Year:</span>
            <span className="project-meta-font flex">{props.year}</span>
          </div>
          <div className="project-role project-meta">
            <span className="project-meta-font pr-4">Role:</span>
            <span className="flex text-xs">{props.role}</span>
          </div>
          <div className="project-brief project-meta">
            <span className="project-meta-font pr-4">Brief:</span>
            <span className="flex text-xs">{props.brief}</span>
          </div>

          {/* { props.isFeatured ? 
                    <Link className="open-single-project rsb-angle-btn" to={`/project/${props.slug}`}>Project Details</Link>
                    : '' } */}
        </div>
      </div>

      {props.isFeatured ? renderBGImg(props) : ""}
    </div>
  );
}
