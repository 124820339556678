import React from "react";
import BackToTop from "./BackToTop";
import { SocialIcon } from "react-social-icons";
import "react-social-icons/linkedin";
import "react-social-icons/behance";
import "react-social-icons/github";
import ReactGA from "react-ga4";

export default function Contact() {
  const handleButtonGAClick = (btnName) => {
    ReactGA.event({
      category: "User",
      action: `Clicked the ${btnName} button`,
    });
  };

  return (
    <>
      <section
        id="contact"
        className="rsb-skin-light bg-[--rsb-bg] pt-14 pb-[100px]"
      >
        <div className="container flex px-5 gap-7 mx-auto flex-col items-start text-[20px]">
          <h3 className="font-black uppercase">Get in touch</h3>
          <p>Let’s see if we can create something cool together!</p>
          <a
            href="mailto:hello@eduardjovanovic.com"
            className="font-black text-xl md:text-6xl"
            onClick={handleButtonGAClick("email")}
          >
            hello@eduardjovanovic.com
          </a>

          <div className="social-icons ml-[-15px]">
            <SocialIcon
              target="_blank"
              bgColor="transparent"
              fgColor="var(--text-color)"
              onClick={handleButtonGAClick("linkedin")}
              url="https://www.linkedin.com/in/resabi/"
            />
            <SocialIcon
              target="_blank"
              bgColor="transparent"
              fgColor="var(--text-color)"
              onClick={handleButtonGAClick("behance")}
              url="https://www.behance.net/EduardJovanovic"
            />
            <SocialIcon
              target="_blank"
              bgColor="transparent"
              fgColor="var(--text-color)"
              onClick={handleButtonGAClick("github")}
              url="https://github.com/resapiens"
            />
          </div>
        </div>
      </section>
      <BackToTop />
    </>
  );
}
