import React from "react";

export default function BackToTop() {
	const [position, setPosition] = React.useState({top: 0, left: 0})
	React.useEffect(() => {
		window.scroll({
		top: position.top,
		left: position.left,
		behavior: 'smooth'
		})
	})
   
	// const [visibility, setVisibility] = React.useState(false)
	const scrollTop = React.useRef()
	React.useEffect(( ) => {
		window.addEventListener('scroll', (e) => {
			window.scrollY > 200 
			? scrollTop.current.style.display = 'inline-block'
			: scrollTop.current.style.display = 'none'
		})
	}) 
 
	return(
		<>
		<span 
			onClick={() => setPosition({...position, position: {top: 0, left: 0}})}
			className="back-to-top rsb-angle-btn"
			ref={scrollTop}
			title="Back to top"
		>To Top</span>
		</>
	)
}