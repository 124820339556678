import React from "react";

import './assets/css/animation.css';
import transition from './transition.js';

import Intro from './components/Intro.js';
import Work from './components/Work.js';
import Logos from './components/Logos.js';
import About from './components/About.js';
import OtherWork from './components/OtherWork.js';

const Home = () => {

	return (
		<>
			<Intro />
			<Work />
			<Logos />
			<About />
			<OtherWork />
		</>
	);
}

// export default Home;
export default transition(Home);




