import React, { useState, useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";

import LogoSingle from "./LogoSingle";
import BackgroundText from "./BackgroundText";
import "../assets/css/logos.css";

//Define animation holder
const AnimatedElement = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (inView && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [inView, hasAnimated]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={hasAnimated ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, delay: delay, ease: "easeOut" }}
    >
      {children}
    </motion.div>
  );
};

const Logos = () => {
  const [data, setData] = useState([]);

  const refLogoBGText = useRef(null);

  useEffect(() => {
    fetch("./logos.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const { scrollYProgress } = useScroll({
    target: refLogoBGText,
    offset: ["start center", "end center"],
  });

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.8, 0.85],
    [0, 0.5, 1, 1, 0]
  );

  return (
    <section
      id="logos"
      className="relative flex items-center justify-center"
      ref={refLogoBGText}
    >
      <motion.div
        className="bg-fixer h-screen fixed top-0 flex flex-col items-center justify-center"
        style={{ opacity }}
      >
        <BackgroundText content="Logo Selection" maxWidth="10em" />
      </motion.div>

      <motion.div className="logo-holder" style={{ opacity }}>
        {data.map((logo, index) => {
          const { key, ...logoProps } = logo;

          return (
            <AnimatedElement delay={index * 0.1}>
              <LogoSingle
                key={key}
                {...logoProps}
                scrollYProgress={scrollYProgress}
              />
            </AnimatedElement>
          );
        })}
      </motion.div>
    </section>
  );
};

export default Logos;
