import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import "./assets/css/animation.css";

import Navbar from "./components/Navbar.js";
import SingleProject from "./components/SingleProject";
import Home from "./Home.js";
import Contact from "./components/Contact.js";

import ReactGA from "react-ga4";
ReactGA.initialize("G-471D3476N4");

function App() {
  // const location = useLocation();

  return (
    <main className="bg-[--rsb-bg] text-[#fff]">
      <Navbar />
      <AnimatePresence mode="wait">
        <Router>
          <Routes>
            {/* <Routes location={location} key={location.pathname}> */}
            <Route index element={<Home />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/*" element={<Home />}></Route>
            <Route
              path="/project/:projectID"
              element={<SingleProject />}
            ></Route>
          </Routes>
        </Router>
      </AnimatePresence>
      <Contact />
    </main>
  );
}

export default App;
