import React from "react";
// import { motion, useTransform } from "framer-motion";

const LogoSingle = (props) => {
  // const ref = useRef(null);

  //Passed from parent container
  // const scrollYProgress = props.scrollYProgress;

  // Single logo animations
  // const opacity = useTransform(
  //   scrollYProgress,
  //   [0, 0.1, 0.2, 0.8, 0.85],
  //   [0, 0.5, 1, 1, 0]
  // );

  // const end = props.endPos;
  // const leftEnd = props.offsetLeft;

  // const x = useTransform(scrollYProgress, [0, 1], [leftEnd, 0]);
  // const y = useTransform(scrollYProgress, [0, 1], [end, 0]);

  // const transform = useMotionTemplate`translate(${x}vw, ${y}vh)`;

  return props.img ? (
    // <motion.div
    //   style={{ opacity }}
    //   ref={ref}
    //   className={`logo-single`}
    //   // start={props.startPos}
    //   // end={props.endPos}
    // >
    //   <img src={require(`../assets/img/logos/${props.img}`)} alt={props.name} />
    // </motion.div>

    <div className={`logo-single`}>
      <img src={require(`../assets/img/logos/${props.img}`)} alt={props.name} />
    </div>
  ) : null;
};

export default LogoSingle;
