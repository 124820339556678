import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import transition from '../transition.js';

function SingleProject() {    

    const { projectID } = useParams();

    const [project, setProject] = useState(null);

    useEffect(() => {
        fetch('/projects.json')
        .then(response => response.json())
        .then(data => {
            const foundProject = data.find(proj => proj.key === projectID);
            setProject(foundProject);
        })
        .catch(error => console.error('Error fetching the project:', error));
    }, [projectID]);


    if((!project) ) {           
        return (
            <main className="bg-[--rsb-bg] text-[#fff]">                
                <section className="container mx-auto flex px-5 py-[20vh] flex-col items-center justify-center text-center text-white">
                    
                    <h1 className="text-6xl md:text-3xl mb-[4vh]">
                        Project not found.
                    </h1>
                    <div>Please go to project list and try again.</div>
                    
                </section>            
            </main>
        )
    } else {

        let renderBGImg = (project) => {
            if((typeof(project.img1) !== 'undefined' && project.img1 != null) ) {           
               return <div className="project-bg-image" style={{backgroundImage: "url(" + require(`../assets/img/projects/${project.img1}`)+ ")" }}></div>;
            }
        }

        return(
            <main className="bg-[--rsb-bg] text-[#fff]">
                <section className="container mx-auto flex px-5 py-[20vh] flex-col mac-width-[700px] items-center justify-center text-center text-white">
                    <h1 className="text-6xl md:text-3xl mb-[4vh]">
                        Project: {project.title}
                    </h1>
                    
                    <div className="project-info text-left basis-1/4 flex flex-col gap-8">
                            <div className="project-year project-meta">
                            <span className="project-meta-font pr-4">Year:</span>
                            <span className="project-meta-font flex">{project.year}</span>
                        </div>
                        <div className="project-role project-meta">
                            <span className="project-meta-font pr-4">Role:</span>
                            <span className="flex text-xs">{project.role}</span>
                        </div>
                        <div className="project-brief project-meta">
                            <span className="project-meta-font pr-4">Brief:</span>
                            <span className="flex text-xs">{project.brief}</span>
                        </div>
                    </div>
                    
                    {renderBGImg(project)}
                </section>
                {/* <section className='single-project-content'>
                    {project.content.map(
                        section => {
                            return <section className={section.key}>section</section>
                        } 
                    )}
                </section> */}
            </main>
        )
    }
}

export default transition(SingleProject);


                // "testimonial" : {
                //     "html": "<p>It is with great pleasure that we at Q-Workforce provide this reference for Eduard Jovanovic, with whom we have had the privilege of working since 2019. Eduard has consistently demonstrated exceptional skill in various IT and design-related tasks. He has been instrumental in the creation of our website, along with several presentations and logos that have enhanced our brand\’s image.</p><p>Eduard\'s design expertise is outstanding, and he is highly skilled in his craft. His ability to quickly understand our needs and deliver high-quality work in a timely manner has been invaluable to us. He is incredibly fast, reliable, and detail-oriented, always exceeding our expectations.</p><p>We warmly recommend Eduard to anyone in need of a talented and efficient IT professional. He will undoubtedly be an asset to any team or project he works on.</p><p class=\'testimonial-author\'>Natasa Zelic, Marketing and Sales at Q-Workforce</p>"
                // },