import React, { Component } from "react";
import Navlink from "./Navlink";
import { SocialIcon } from "react-social-icons";
import "../assets/css/nav.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { menuToggle: "closed" };

    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
    var navMobClasses =
      this.state.menuToggle === "closed" ? "toggled-on" : "closed";
    this.setState({ menuToggle: navMobClasses });
  }

  render() {
    return (
      <>
        <header className="site-header top-0">
          <div className="mx-auto flex p-5 px-8 flex-row items-center justify-between md:justify-center">
            <nav className="desktop-nav hidden md:flex md:mr-auto md:py-1 flex-wrap items-center text-base justify-start gap-10">
              <Navlink anchor="intro" text="Home"></Navlink>
              <Navlink anchor="work" text="Work"></Navlink>
              <Navlink anchor="about" text="About me"></Navlink>
              <Navlink anchor="contact" text="Contact"></Navlink>
            </nav>

            <div className="logo title-font font-medium text-[#D9D9D9] text-xl">
              {/* <Navlink anchor="intro" text="EJ"></Navlink> */}
              <a
                href="#intro"
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById("intro");
                  element?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <svg
                  width="56"
                  height="58"
                  viewBox="0 0 56 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.16038 11.6171L6.37618 34.8148L9.25589 34.0432L3.81171 13.7252L21.09 9.09548L25.7625 26.5337L28.6423 25.7621L23.9697 8.32387L41.4079 3.6513L46.8521 23.9693L49.7318 23.1977L43.516 -2.23048e-05L0.16038 11.6171Z"
                    fill="currentColor"
                  />
                  <path
                    d="M27.4737 56.5969C12.7905 60.5313 9.47413 47.0164 9.47413 47.0164L12.3538 46.2448C15.457 57.9306 27.8219 53.4172 27.8219 53.4172L54.3792 46.3012L55.1508 49.1809L27.4737 56.5969Z"
                    fill="currentColor"
                  />
                  <path
                    d="M13.353 22.3142L17.3526 21.2425L18.9816 27.3219L14.982 28.3936L13.353 22.3142Z"
                    fill="currentColor"
                  />
                  <path
                    d="M34.4712 16.6557L38.4708 15.584L40.0998 21.6634L36.1002 22.7351L34.4712 16.6557Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>

            <button
              className={`mobile-opener inline-flex md:hidden ${this.state.menuToggle}`}
              onClick={this.toggleNav.bind(this)}
            >
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="28px"
                height="30px"
                viewBox="0 0 28 30"
              >
                <rect
                  x="0"
                  y="6"
                  width="30"
                  fill="currentColor"
                  height="2"
                ></rect>
                <rect
                  x="0"
                  y="14"
                  width="30"
                  fill="currentColor"
                  height="2"
                ></rect>
                <rect
                  x="0"
                  y="22"
                  width="30"
                  fill="currentColor"
                  height="2"
                ></rect>
              </svg>
            </button>

            <div className="social-icons ml-[-15px] text-white">
              <SocialIcon
                target="_blank"
                bgColor="transparent"
                fgColor="var(--text-color)"
                url="https://www.linkedin.com/in/resabi/"
              />
              <SocialIcon
                target="_blank"
                bgColor="transparent"
                fgColor="var(--text-color)"
                url="https://www.behance.net/EduardJovanovic"
              />
              <SocialIcon
                target="_blank"
                bgColor="transparent"
                fgColor="var(--text-color)"
                url="https://github.com/resapiens"
              />
            </div>
          </div>
        </header>
        <nav
          id="mobile-menu"
          className={`md:hidden mobile-menu flex flex-col sticky top-20 z-[99] ${this.state.menuToggle}`}
        >
          <div className="py-8">
            <Navlink
              anchor="intro"
              text="Home"
              toggleNav={this.toggleNav}
            ></Navlink>
            <Navlink
              anchor="work"
              text="Featured Projects"
              toggleNav={this.toggleNav}
            ></Navlink>
            <Navlink
              anchor="logos"
              text="Logos"
              toggleNav={this.toggleNav}
            ></Navlink>
            <Navlink
              anchor="about"
              text="About me"
              toggleNav={this.toggleNav}
            ></Navlink>
            <Navlink
              anchor="past-work"
              text="Past Work"
              toggleNav={this.toggleNav}
            ></Navlink>
            <Navlink
              anchor="contact"
              text="Contact"
              toggleNav={this.toggleNav}
            ></Navlink>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
